document.addEventListener('DOMContentMutated', () => {
    let modalCloser = document.querySelector('.finish__closer');

    if (modalCloser) {
        modalCloser.addEventListener('click', (e) => {
            e.preventDefault();
            console.log(document.querySelectorAll('.modal__closer'));
            document.querySelectorAll('.modal__closer')[3].click();
        })
    }
});

