<template>
    <svg width="18px" height="15px" viewBox="0 0 18 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 52.3 (67297) - http://www.bohemiancoding.com/sketch -->
        <title>Line 3 Copy 14</title>
        <desc>Created with Sketch.</desc>
        <g id="Карточка-проекта" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
            <g id="Карточка-проекта-1920" transform="translate(-1153.000000, -2543.000000)" stroke="#77B156" stroke-width="2">
                <g id="Group-4" transform="translate(222.000000, 2521.000000)">
                    <polyline id="Line-3-Copy-14" points="933 30.9130408 937.376068 35 947 24"></polyline>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "CheckmarkInfoIcon"
    }
</script>

<style scoped>

</style>