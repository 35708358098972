<template>
    <div>
        <MenuList/>
        <QuestionModalItem v-if="modal"/>
        <QuestionItem v-else />
        <WallGroupList/>
        <WallList/>
        <FoundationList/>
        <RoofList/>
        <KitInfoList v-if="!modal"/>
    </div>

</template>

<script>
    import MenuList from "./components/KitList.vue";
    import WallGroupList from "./components/WallGroupList.vue";
    import WallList from "./components/WallList.vue";
    import FoundationList from "./components/FoundationList.vue";
    import RoofList from "./components/RoofList.vue";
    import QuestionItem from "./components/QuestionItem.vue";
    import KitInfoList from "./components/KitInfoList.vue";
    import QuestionModalItem from "./components/QuestionModalItem.vue";

    export default {
        name: 'App',
        props: ['modal'],
        components: {
            QuestionModalItem,
            KitInfoList,
            QuestionItem,
            FoundationList,
            WallGroupList,
            MenuList,
            WallList,
            RoofList
        }
    }
</script>

<style lang="scss">

</style>