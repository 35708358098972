<template>
    <div>
        <span class="calculator__price-value">{{normalPrice}}Р</span>
    </div>
</template>

<script>
    import TweenLite from 'gsap';
    export default {
        name: "PriceModalItem",
        props: {
            price: Number
        },
        data() {
            return {
                tweeningPrice: 0
            }
        },
        computed: {
            normalPrice() {
                return this.tweeningPrice.toFixed(0).toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
            }
        },
        mounted() {
            this.tweeningPrice = this.price;
        },
        watch: {
            price (newValue) {
                TweenLite.to(this.$data, 0.5, {tweeningPrice: newValue});
            }
        },
    }
</script>

<style>

</style>