<template>
    <svg width="99px" height="99px" viewBox="0 0 99 99" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
        <title>Group</title>
        <desc>Created with Sketch.</desc>
        <g id="Карточка-проекта" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard-Copy-3" transform="translate(-65.000000, -147.000000)">
                <g id="Group" transform="translate(65.000000, 147.000000)">
                    <circle id="Oval" fill="#F6F6F1" cx="49.5" cy="49.5" r="49.5"></circle>
                    <polyline id="Line-3-Copy" stroke="#77B156" stroke-width="5" stroke-linecap="square" points="35 53.7114565 45.002442 63 67 38"></polyline>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "SuccessIcon"
    }
</script>

<style scoped>

</style>