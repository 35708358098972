<template>
    <div class="calculator__price" data-selector=".default-form__data">
        <div class="calculator__price-left">
            <span class="calculator__price-label">Итого:</span>
            <PriceModalItem :price="price"/>
        </div>
        <div class="calculator__price-right">
            <a href="/request/recall_modal" data-modal class="calculator__price-button" @click.prevent="handleClick">Хочу такой дом</a>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import PriceModalItem from './components/PriceModalItem.vue';
    import zenscroll from "zenscroll";

    export default {
        name: "PriceModal",
        computed: {
            ...mapGetters('options', [
                'price'
            ])
        },
        created() {
            this.$store.dispatch('options/fetchPrice');
        },
        methods: {
            handleClick() {
                let target = null;
                let container = document.querySelector('.modal__bg');
                let mobileContainer = document.querySelector('.modal__container');
                if (this.$el.dataset.selector) {
                    target = document.querySelector(this.$el.dataset.selector);
                } else {
                    target = document.querySelector(this.$el.getAttribute('href'));
                }
                // let offset = this.$el.firstChild.dataset.offset || 0;
                let offset = 0;
                if (target) {
                    let viewportOffset = target.getBoundingClientRect();
                    let top = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
                    container.scrollTo({
                        top:viewportOffset.top + offset + top,
                        behavior: 'smooth'
                    })
                    mobileContainer.scrollTo({
                        top:viewportOffset.top + offset + top,
                        behavior: 'smooth'
                    })
                    // zenscroll.toY(top + viewportOffset.top + offset, 500);
                }
            },
        },
        components: {
            PriceModalItem
        }
    }
</script>

<style>

</style>