<template>
    <div>
        <form action="" method="post" class="project__offer-form">
            <input data-phone-field type="text" placeholder="Телефон для связи" v-model="phone" />
            <span class="project__offer-form-error" v-for="error in formErrors.phone" :key="error">
                {{error}}.
            </span>
            <button type="submit" class="project__offer-button button_green" @click.prevent="handleClick">Оставить заявку</button>
            <p class="project__offer-policy">
                Нажимая на кнопку, вы
                соглашаетесь с условиями
                <a :href="policyLink" target="_blank" class="project__offer-policy-link">
                    политики конфиденциальности
                </a>
             </p>
        </form>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: "RecallForm",
        props: {
            policyLink: String,
            offerGoal: String
        },
        computed: {
            phone: {
                get() {
                    return this.$store.state.phone
                },
                set(value) {
                    this.$store.commit('options/updatePhone', value);
                }
            },
                ...mapGetters ('options', [
                    'formErrors',
                    'categoryId'
                ])
        },
        methods: {
            handleClick() {
                this.$store.dispatch('options/storePhone', {
                    offerGoal: this.offerGoal,

                });
            }
        },
        mounted() {
            this.$store.dispatch('options/storeCategoryId');
        }
    }
</script>

<style lang="scss">
    .project__offer-form-error {
        font-size: 14px;
        color: #b50000
    }
</style>