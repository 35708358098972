function setActiveProjectsMenu() {
    let menuCategoryLink = document.querySelector('[data-category-toggle]');
    let menuCategory = document.querySelector('.projects-menu');

    if (menuCategoryLink) {
        let elements = [];
        elements.push(menuCategoryLink);
        elements.push(menuCategory);

        if (elements.length > 0) {
            elements.forEach(elem => {
                if (elem) {
                    elem.addEventListener('mouseenter', function () {
                        menuCategory.classList.add('active');
                        menuCategoryLink.classList.add('active');
                    });
                }
                if (elem) {
                    elem.addEventListener('mouseleave', function () {
                        menuCategory.classList.remove('active');
                        menuCategoryLink.classList.remove('active');
                    })
                }
            })
        }
    }
}

function setActiveProductsMenu() {
    let menuProductsLink = document.querySelector('[data-products-toggle]');
    let menuProducts = document.querySelector('.products-menu');

    if (menuProductsLink) {
        let elements = [];
        elements.push(menuProductsLink);
        elements.push(menuProducts);
        if (elements.length > 0) {
            elements.forEach(elem => {
                if (elem) {
                    elem.addEventListener('mouseenter', function () {
                        menuProducts.classList.add('active');
                        menuProductsLink.classList.add('active');
                    });
                }
                if (elem) {
                    elem.addEventListener('mouseleave', function () {
                        menuProducts.classList.remove('active');
                        menuProductsLink.classList.remove('active');
                    })
                }
            })
        }
    }
}

function setActiveImage() {
    let linkParents = document.querySelectorAll('[data-image-link]');
    linkParents.forEach(linkParent => {
        let linkList = linkParent.children;
        let imageList = document.querySelector(linkParent.getAttribute('data-image-link')).children;
        linkList = Array.prototype.slice.call(linkList);
        imageList = Array.prototype.slice.call(imageList);
        if (linkList.length > 0) {
            linkList.forEach(link => {
                link.addEventListener('mouseenter', function () {
                    let index = linkList.indexOf(link);
                    imageList.forEach(image => {
                        image.style.opacity = 0;
                    });
                    imageList[index].style.opacity = 1;
                });
            })
        }
    })
}

setActiveProjectsMenu();
setActiveProductsMenu();
setActiveImage();

