import axios from 'axios';
const getRequestPrice = (http, state, commit) => {
    axios.get(http, {
        params: {
            project: state.activeProject.id || window.projectId,
            activeKit: state.activeKit.id,
            activeWallGroup: state.activeWallGroup.id,
            activeWall: state.activeWall.id,
            activeFoundation: state.activeFoundation.id,
            activeRoof: state.activeRoof.id
        }})
        .then((response) => {
            let payload = response.data;
            commit('setPrice', payload);
        })
        .catch(err => console.log(err))
};

export default getRequestPrice;