<template>
    <div class="project__offer-mobile">
        <PriceMobileItem :price="forcePrice" v-if="forcePrice>0"/>
        <PriceMobileItem :price="price" v-else/>
        <a href="javascript:void(0)" class="project__offer-mobile-order">
            <span class="project__offer-mobile-order-label">Заказать <br>индивидуальный проект</span>
        </a>
        <a href="/request/recall_modal" class="project__offer-mobile-button" data-modal>Хочу такой дом</a>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import PriceMobileItem from "./components/PriceMobileItem.vue";
    export default {
        name: "PriceMobile",
        components: {PriceMobileItem},
        props: {
            forcePrice: Number
        },
        computed: {
            ...mapGetters('options',[
                'price'
            ])
        }
    }
</script>

<style lang="scss">

</style>