<template>
    <li class="roof-item" :class="{active:isActive}" v-if="hidden < 1">
        <a href="javascript:void(0)" class="roof-item__link" @click.prevent="handleClick(id)">
            <span class="roof-item__label">{{name}}</span>
            <span class="roof-item__image" :style="{backgroundImage: `url(${imageLink})`}"></span>
        </a>
        <span class="roof-item__icon"><CheckMarkIcon/></span>
    </li>
</template>

<script>
    import CheckMarkIcon from '../icons/CheckmarkIcon.vue';
    export default {
        name: "RoofItem",
        props: {
            id: String,
            name: String,
            isActive: Boolean,
            image: String,
            hidden: String
        },
        methods: {
            handleClick(id) {
                this.$store.dispatch('options/makeRoofActive', id);
            }
        },
        components: {
            CheckMarkIcon
        },
        computed: {
            imageLink() {
                return `/media/${this.image}`;
            }
        }
    }
</script>

<style lang="scss">

</style>