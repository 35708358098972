import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

let modal;

new LiveEvent('click', '[data-modal]', function openModal(e) {
    e.preventDefault();
    let link = this;
    modal = new Modal(this, {
        closerText: '',
        theme: link.dataset.theme ? link.dataset.theme : 'default',
        onFormSuccess: function () {
            if (link.dataset.goal) {
                window.goal(link.dataset.goal);
            }
            if (carrotquest) {
                carrotquest.track('Успешное заполнение формы');
            }
        }
    });
    return false;
});

document.addEventListener('success', function (e) {
    if (e.detail && e.detail.form.dataset.ajaxModalForm !== undefined) {
        modal.close();
    }
});