import {TimelineMax, Bounce} from 'gsap';

let button = document.querySelector('[data-menu-link]');
let body = document.querySelector('body');
let menu = document.querySelector('[data-menu-toggle]');
let easingOut = "Elastic.easeOut";
let easingIn = "Elastic.easeIn";
let tl = new TimelineMax({paused: true});
    tl
        .to('.menu-icon-item1', 0.5, {y: "7",ease: easingIn }, 0)
        .to('.menu-icon-item3', 0.5, {y: "-7", ease: easingIn}, 0)
        .to('.menu-icon-item2', 0.1, {opacity: 0, ease: Linear.easeNone },)
        .to('.menu-icon-item1', 0.4, {rotation: 45, ease: easingOut }, "0.5")
        .to('.menu-icon-item3', 0.4, {rotation: -45, ease: easingOut }, "0.5")
tl.reverse();

button.onclick = function (e) {
    e.preventDefault();
    menu.classList.toggle('active');
    body.classList.toggle('menu-active');

    tl.reversed(!tl.reversed());
};



