require('../scss/app.scss');
require('../fonts/Geometria/css/Geometria.css');

window.noZensmooth = true;

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/map.js');

require('./field/phone.js');
require('./field/range.js');
require('./field/sort.js');
require('../components/smart-tabs/smart-tabs');
require('./sliders');
require('./emergence');
require('./calcHelper');
require('./parallaxSliders');
require('./filter');
require('./modal');
require('./headerSticky');

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');
require('./menu');
require('./categoryMenu');
require('./animation');
require('./preloader');

import Vue from 'vue';
import App from './vue/App.vue';
import Price from './vue/Price.vue';
import PriceMobile from './vue/PriceMobile.vue';
import PriceModal from './vue/PriceModal.vue';
import Projects from './vue/Projects.vue';
import store from './vue/store';


document.addEventListener('DOMContentMutated', () => {
    if (document.querySelector('#app')) {
        const app = new Vue({
            el: '#app',
            store,
            render: h => h(App, {
                props: {
                    modal: true
                },
            })
        });
    }

    if (document.querySelector('#price-modal')) {
        const priceModal = new Vue({
            el: '#price-modal',
            store,
            render: h => h(PriceModal)
        });
    }

    if (document.querySelector('#projects')) {
        const projects = new Vue({
            el: '#projects',
            store,
            render: h=>h(Projects)
        });
    }
});

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('#app')) {
        const app = new Vue({
            el: '#app',
            store,
            render: h => h(App, {
                props: {
                    modal: false,
                },
            })
        });
    }

    if (document.querySelector('#price')) {
        window.projectId = document.querySelector('[data-project]').dataset.project;
        window.categoryId = document.querySelector('[data-category]').dataset.category;
        let offer = document.querySelector('.project__offer');
        let policyLink = offer.getAttribute('data-link-policy');
        let offerLink = offer.getAttribute('data-link-offer');
        let offerGoal = offer.getAttribute('data-offer-goal');

        let countPrice = 0;
        if (document.querySelector('[data-price]')) {
            countPrice = parseInt(document.querySelector('[data-price]').dataset.price, 10) || 0;
        }

        const price = new Vue({
            el: '#price',
            store,
            render: h => h(Price, {
                props: {
                    policyLink: policyLink,
                    offerLink: offerLink,
                    forcePrice: countPrice,
                    offerGoal: offerGoal
                }
            })
        });
    }

    if (document.querySelector('#price-mobile')) {
        let countPrice = 0;
        if (document.querySelector('[data-price]')) {
            countPrice = parseInt(document.querySelector('[data-price]').dataset.price, 10) || 0;
        }
        const priceMobile = new Vue({
            el: '#price-mobile',
            store,
            render: h => h(PriceMobile, {
                props: {
                    forcePrice: countPrice
                }
            })
        });
    }
});


