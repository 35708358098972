<template>
    <div class="project__order-success">
        <span class="project__order-success-icon"><SuccessIcon/></span>
        <h2 class="project__order-success-status">Вы успешно оставили заявку</h2>
        <p class="project__order-success-description">Мы свяжемся с вами в ближайшее время и обговорим детали</p>
    </div>
</template>

<script>
    import SuccessIcon from "../icons/SuccessIcon.vue";
    export default {
        name: "SuccessItem",
        components: {SuccessIcon}
    }
</script>

<style>

</style>