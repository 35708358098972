<template>
    <div>
        <a href=".kit-info" data-scroll-link class="options__question" @click.prevent = "handleClickLink">
            <span class="options__question-icon"><QuestionIcon/></span>
            <span class="options__question-label">Что входит в {{activeKit.verboseName}}?</span>
        </a>
    </div>
</template>

<script>
    import QuestionIcon from '../icons/QuestionIcon.vue';
    import {mapGetters} from 'vuex';
    import zenscroll from 'zenscroll';

    export default {
        name: "QuestionItem",
        methods: {
          handleClickLink() {

              let target = null;
              if (this.$el.dataset.selector) {
                  target = document.querySelector(this.$el.dataset.selector);
              } else {
                  target = document.querySelector(this.$el.getAttribute('href'));
              }
              // let offset = this.$el.firstChild.dataset.offset || 0;
              let offset = 0;
              if (target) {
                  let viewportOffset = target.getBoundingClientRect();
                  let top = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
                  container.scrollTo({
                      top:viewportOffset.top + offset + top,
                      behavior: 'smooth'
                  });
                  zenscroll.toY(top + viewportOffset.top + offset, 500);
              }
          },
        },
        computed: {
            ...mapGetters ('options', [
                'activeKit'
            ])
        },
        components: {
            QuestionIcon
        }
    }
</script>

<style lang="scss">
    .options {
        &__question {
            display: flex;
            align-items: center;
        }
        &__question-icon {
            margin-right: 12px;
        }
        &__question-label {
            position: relative;
            top: -1px;
            font-size: 17px;
            color: #77B156;
            border-bottom: 1px dashed #77B156;
            transition: color .4s ease-in-out, border .4s ease-in-out;

            &:hover {
                color: #5E963E;
                border-bottom: 1px dashed #5E963E;
            }
        }
    }
</style>