<template>
    <li class="foundation-item" :class="{active:isActive}">
        <a href="javascript:void(0)" class="foundation-item__link" @click.prevent="handleClick(id)">
            <span class="foundation-item__label">{{name}}</span>
            <img class="foundation-item__image" :src="imageLink" v-if="image">
        </a>
        <span class="foundation-item__icon">
            <CheckmarkIcon/>
        </span>

    </li>
</template>

<script>
    import CheckmarkIcon from '../icons/CheckmarkIcon.vue';
    export default {
        name: "FoundationItem",
        props: {
            id: String,
            name: String,
            isActive: Boolean,
            image: String
        },
        methods: {
             handleClick(id) {
                 this.$store.dispatch('options/makeFoundationActive', id);
            }
        },
        computed: {
            imageLink() {
                return `/media/${this.image}`;
            }
        },
        components: {
            CheckmarkIcon
        }
    }
</script>

<style lang="scss">

</style>