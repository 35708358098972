<template>
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
        <title>Group 23</title>
        <desc>Created with Sketch.</desc>
        <g id="Каталог-проектов" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-260.000000, -1462.000000)" id="Group-13-Copy-6" stroke="#77B156">
                <g transform="translate(150.000000, 1235.000000)">
                    <g id="Group-25" transform="translate(110.000000, 227.000000)">
                        <g id="Group-23">
                            <rect id="Rectangle" stroke-width="2" x="1" y="7" width="14" height="14"></rect>
                            <g id="Group-21" transform="translate(17.000000, 6.000000)">
                                <rect id="Rectangle" fill="#FFFFFF" x="2.5" y="1.5" width="0.1" height="13"></rect>
                                <polyline id="Line-5" transform="translate(2.437195, 1.299683) rotate(-180.000000) translate(-2.437195, -1.299683) " points="0.712036133 0.60534668 2.34350586 1.99401855 4.16235352 0.60534668"></polyline>
                                <polyline id="Line-5" transform="translate(2.530884, 14.605347) rotate(-360.000000) translate(-2.530884, -14.605347) " points="0.805725098 13.9110107 2.43719482 15.2996826 4.25604248 13.9110107"></polyline>
                            </g>
                            <g id="Group-21" transform="translate(8.000000, 2.500000) rotate(-90.000000) translate(-8.000000, -2.500000) translate(5.500000, -5.500000)">
                                <rect id="Rectangle" fill="#FFFFFF" x="2.5" y="1.5" width="0.1" height="13"></rect>
                                <polyline id="Line-5" transform="translate(2.437195, 1.299683) rotate(-180.000000) translate(-2.437195, -1.299683) " points="0.712036133 0.60534668 2.34350586 1.99401855 4.16235352 0.60534668"></polyline>
                                <polyline id="Line-5" transform="translate(2.530884, 14.605347) rotate(-360.000000) translate(-2.530884, -14.605347) " points="0.805725098 13.9110107 2.43719482 15.2996826 4.25604248 13.9110107"></polyline>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "PerimeterIcon"
    }
</script>

<style scoped>

</style>