import _ from "lodash";


let form = $('[data-filter-form]');

function update() {
  $.ajax({
    data: form.serialize()
  }).then((html) => {
      replaceContent(html);
  });
}

function replaceContent(html) {
  let page = $('<ul/>').append(html);
  $('[data-filter-data]').replaceWith(page.find('[data-filter-data]'));
}

form.find('.checkbox-list input, .radio-list input, .sort-input input').on('change', update);
form.find('.range-input input').on('keyup change', _.debounce(update, 500));