<template>
    <li class="kit-info-item">
        <span class="kit-info-item__name">{{name}}</span>
        <span class="kit-info-item__icon">
            <CheckmarkIcon v-if="check == 1"/>
            <CrossIcon v-if="check !=1 "/>
        </span>
    </li>
</template>

<script>
    import CheckmarkIcon from "../icons/CheckmarkInfoIcon.vue";
    import CrossIcon from "../icons/CrossIcon.vue";
    export default {
        name: "KitInfoItem",
        props: {
            id: String,
            name: String,
            check: String
        },
        computed: {

        },
        components: {
            CrossIcon,
            CheckmarkIcon
        },
    }
</script>

<style lang="scss">


</style>