<template>
    <div class="material" v-if="wallGroupMaterials">
        <h3 class="material__title">Материал стен</h3>
        <ul class="material__list">
            <WallGroupItem v-for="wallGroup in wallGroupMaterials" :key="wallGroup.id"
                :id="wallGroup.id"
                :name="wallGroup.name"
                :isActive="wallGroup.isActive"
                :image="wallGroup.image"
            />
        </ul>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import WallGroupItem from './WallGroupItem.vue';
    export default {
        name: "WallGroupList",
        mounted() {
            this.$store.dispatch('options/fetchOptions');
        },
        computed: {
            ...mapGetters('options', [
                'wallGroupMaterials'
            ])
        },
        components: {
            WallGroupItem
        },
    }
</script>

<style lang="scss">

</style>