<template>
    <div class="wall" v-if="wallMaterials">
        <h3 class="wall__title">Диаметр бревна</h3>
        <!--<ul class="wall__list">-->
            <ul class="wall__list">
                <WallItem v-for="wallMaterial in wallMaterials" :key="wallMaterial.id"
                          :id="wallMaterial.id"
                          :name="wallMaterial.name"
                          :isActive="wallMaterial.isActive"
                />
            </ul>
        <!--</ul>-->
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import WallItem from './WallItem.vue';

    export default {
        name: "WallList",
        computed: {
            ...mapGetters('options', [
                'wallMaterials'
            ])
        },
        components: {
            WallItem
        }
    }
</script>

<style lang="scss">


    .list-enter-active {
        transition: all .8s;
    }

    .list-leave-active {
        position: absolute;
    }


    .list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */
    {
        opacity: 0;
        transform: translateY(30px);
    }
</style>