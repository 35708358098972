export default class ParallaxSlider {
    constructor(options) {
        this.options = options;
        this.slider = document.querySelector(this.options.slider);
        this.childs = this.slider.children;
        this.body = document.querySelector('body');
        this.init();
        }

    init() {
        this.slider.style.overflow = 'hidden';
        this.slider.classList.add('slider');
        let childs = this.slider.children;

        if (childs.length > 1) {
            for (let i = 0; i < childs.length; i++) {
                childs[i].style.height = '100%';
                childs[i].style.width = '100%';
                childs[i].style.position = 'absolute';
                childs[i].style.left = '100%';
                childs[i].classList.add('slider__item');
                childs[i].style.top = '0';
            }
        } else {
            childs[0].style.height = '100%';
            // childs[0].style.width = this.wrapper.style.width;
            // let style = getComputedStyle(this.wrapper);
            // let childWidth = style.getPropertyValue('width');
            // childs[0].style.width = childWidth;
        }
        childs[0].classList.add('active');
    }

    next() {
        let currentSlide = this.slider.querySelector('.active');
        let nextSlide = currentSlide.nextElementSibling;
        if (nextSlide == null) {
            nextSlide = this.childs[0];
        }

        let moveSlide = new Promise((resolve, reject) => {
            currentSlide.classList.add('out');
            nextSlide.classList.add('in');
            this.body.classList.add('is-animating');
            setTimeout(() => resolve('work'), 1000);
        });

        moveSlide.then(() => {
            currentSlide.classList.remove('out');
            nextSlide.classList.remove('in');
            nextSlide.classList.add('active');
            currentSlide.classList.remove('active');
            this.body.classList.remove('is-animating');
        })}


    prev() {
        let currentSlide = this.slider.querySelector('.active');
        let nextSlide = currentSlide.previousElementSibling;
        if (nextSlide === null) {
            length = this.childs.length;
            nextSlide = this.childs[length-1];
        }

        let moveSlide = new Promise((resolve, reject) => {
            currentSlide.classList.add('out-reverse');
            nextSlide.classList.add('in-reverse');
            this.body.classList.add('is-animating');
            setTimeout(() => resolve('work'), 1000);
        });

        moveSlide.then(() => {
            currentSlide.classList.remove('out-reverse');
            nextSlide.classList.remove('in-reverse');
            currentSlide.classList.remove('active');
            nextSlide.classList.add('active');
            this.body.classList.remove('is-animating');
        })
    }
}