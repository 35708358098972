<template>
    <transition name="fade">
        <div class="foundation" v-if="activeKit.id !== 1 && foundations">
            <h3 class="foundation__title">Тип фундамента</h3>
            <ul class="foundation__list">
                <FoundationItem v-for="foundation in foundations" :key="foundation.id"
                                :id="foundation.id"
                                :name="foundation.name"
                                :isActive="foundation.isActive"
                                :image="foundation.image"
                />
            </ul>
        </div>
    </transition>
</template>

<script>
    import {mapGetters} from 'vuex';
    import FoundationItem from "./FoundationItem.vue";
    export default {
        name: "FoundationList",
        computed: {
            ...mapGetters('options',[
                'foundations',
                'activeKit'
            ])
        },
        components: {
            FoundationItem
        }
    }
</script>

<style lang="scss">


    .fade-enter-active, .fade-leave-active {
        transition: all .8s;
    }

    .fade-enter, .fade-leave-to /* .list-leave-active до версии 2.1.8 */{
        opacity: 0;
        transform: translateY(30px);
    }
</style>