<template>
    <div class="calculator__project" v-if="calculatedProjects">
        <ul class="calculator__project-list">
            <ProjectItem v-for="project in calculatedProjects" :key="project.id"
                         :name="project.calculator_name"
                         :area="project.area_living"
                         :width="project.width"
                         :length="project.length"
                         :image="project.images[0].image"
                         :id="project.id"
                         :isActive="project.isActive"
            />
        </ul>
    </div>
</template>

<script>
    import ProjectItem from "./components/ProjectItem.vue";
    import { mapGetters } from "vuex";

    export default {
        name: "Project",
        mounted() {
            this.$store.dispatch('options/fetchHouses');
        },
        computed: {
            ...mapGetters('options', [
                'calculatedProjects',
            ])
        },
        components: {
            ProjectItem
        }
    }

</script>

<style lang="scss">

</style>