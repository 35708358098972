let sortLinks = document.querySelectorAll('.sort-input__link');
let sortInput = document.querySelector('.sort-input__input');
let changeEvent = new Event('change');

sortLinks.forEach(function(sortLink) {
    sortLink.addEventListener("click", function() {
        sortLinks.forEach(function(sortLink) {
            sortLink.classList.remove('active');
            sortLink.classList.remove('sort-input__link_rotate');
        });
        if (sortInput.value === sortLink.dataset.sortValue) {
            sortInput.value = '-' + sortLink.dataset.sortValue;
            sortLink.classList.add('sort-input__link_rotate');
        } else {
            sortInput.value = sortLink.dataset.sortValue;
        }
        sortInput.dispatchEvent(changeEvent);
        sortLink.classList.add('active');
    });
});