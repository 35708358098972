<template>
    <svg width="45px" height="25px" viewBox="0 0 45 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
        <title>₽</title>
        <desc>Created with Sketch.</desc>
        <g id="Карточка-проекта" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-1042.000000, -1031.000000)">
                <rect fill="#FFFFFF" x="0" y="0" width="1500" height="5563"></rect>
                <text id="₽" font-family="Geometria-ExtraBold, Geometria" font-size="35" font-weight="600" fill="#333531">
                    <tspan x="1042" y="1056">  ₽</tspan>
                </text>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "RubIcon"
    }
</script>

<style scoped>

</style>