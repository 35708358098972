import {TimelineMax, Power2, TweenMax} from "gsap";

let easeFunc = Power2.easeOut;

function animateOnLoad() {
    lazyLoadStart();
    let slider = document.querySelector('.index-houses_houses .index-houses__slider');
    let info = document.querySelector('.index-houses_houses .index-houses__info-left');
    let tl = new TimelineMax();
    if (slider && info) {
        tl
            .to(slider, 1.4, {x: 0, opacity: 1, ease: easeFunc}, "0.2")
            .to('.index-page__title', 1.4, {y: 0, opacity: 1, ease: easeFunc}, "0")
            .to(info, 1, {opacity: 1, y: 0, ease: easeFunc}, "0.5")
            .to('.index-houses_houses .index-houses__info-right', 0.8, {opacity: 1, y: 0, ease: easeFunc}, "0.85")
            .to('.header-index', 0.9, {y: 0, opacity: 1, ease: easeFunc}, "0.8")
    }
}

function animateOnScroll() {
    let blocks = document.querySelectorAll('[data-animation]');
    if (blocks) {
        blocks.forEach((block) => {
            window.addEventListener('scroll', () => {
                if (window.innerWidth >= 1366) {
                    let y = block.getBoundingClientRect().top - window.innerHeight;
                    let margin = 0.15 * window.innerHeight;
                    let startY = y + margin;
                    if (startY < 0) {
                        startAnimate(block)
                    }
                }
            })
        })
    }
}

function startAnimate(block) {
    switch (block.dataset.animation) {
        case "bathrooms":
            let tl = new TimelineMax();
            tl
                .to('.index-houses_bathrooms .index-houses__slider', 1.4, {x: 0, opacity: 1, ease: easeFunc}, "0")
                .to('.index-houses_bathrooms .index-houses__info-left', 1, {y: 0, opacity: 1, ease: easeFunc}, "0.5")
                .to('.index-houses_bathrooms .index-houses__info-right', 0.65, {
                    y: 0,
                    opacity: 1,
                    ease: easeFunc
                }, "0.85")
            break;
        case "small-forms":
            let tl2 = new TimelineMax();
            tl2
                .to('.index-houses_small-forms .index-houses__slider', 1.4, {x: 0, opacity: 1, ease: easeFunc}, "0")
                .to('.index-houses_small-forms .index-houses__info-left', 1, {y: 0, opacity: 1, ease: easeFunc}, "0.5")
                .to('.index-houses_small-forms .index-houses__info-right', 0.65, {
                    y: 0,
                    opacity: 1,
                    ease: easeFunc
                }, "0.85")
            break;
        case "production":
            lazyLoad();
            let tl3 = new TimelineMax();
            tl3
                .to('.index-page__production-title', 1.2, {y: 0, opacity: 1, ease: easeFunc}, "0")
                .to('.production-item', 0.92, {scale: 1, opacity: 1, ease: easeFunc}, "0.45")
            break;
        case "works":
            let tl4 = new TimelineMax();
            tl4
                .to('.index-works__title', 1, {y: 0, opacity: 1, ease: easeFunc}, "0")
                .to('.index-works__main', 1.2, {y: 0, opacity: 1, ease: easeFunc}, "0.25")
            break;
        case "order":
            let tl5 = new TimelineMax();
            let orderItems = document.querySelectorAll('.order-block__item')
            tl5
                .to(orderItems, 1.05, {opacity: 1, x: 1, ease: easeFunc}, "0")
            break;
        case "about":
            let tl6 = new TimelineMax();
            tl6
                .to('.about-block__title', 1, {opacity: 1, y: 0, ease: easeFunc}, "0")
                .to('.about-video', 1.45, {opacity: 1, x: 0, ease: easeFunc}, "0.15")
                .staggerTo('.about-block__item', 1, {y: 0, opacity: 1}, "0.25", "0.2")
            break;
        case "actions":
            let tl7 = new TimelineMax();
            tl7
                .to('.actions-block__main', 1.5, {scale: 1, y: 0, opacity: 1, ease: easeFunc}, "0")
            break;
        case "advantages":
            let tl8 = new TimelineMax();
            tl8
                .staggerTo('.advantages__item', 0.75, {scale: 1, y: 0, opacity: 1, ease: easeFunc}, "0.2", "0")
            break;
        case "geography":
            console.log('work geography');
            let tl9 = new TimelineMax();
            tl9
                .to('.geography__russia', 1.65, {x: 0, opacity: 1, ease: easeFunc}, "0")
                .staggerTo('.geography__item', 0.7, {y: 0, opacity: 1, ease: easeFunc}, "0.2", "1.1")
                .staggerTo('.geography__number', 0.9, {y: 0, opacity: 1, ease: easeFunc}, "0.2", "0.8")
                .to('.geography__about', 1, {x: 0, opacity: 1, ease: easeFunc}, "0.9")
            break;
        case "recall":
            let tl10 = new TimelineMax();
            tl10
                .to('.recall__man', 1.3, {x: 0, opacity: 1, ease: easeFunc}, "0")
                .to('.recall__container', 1.3, {x: 0, opacity: 1, ease: easeFunc}, "0")
            break;
        case "footer":
            let tl11 = new TimelineMax();
            tl11
                .to('.footer__container', 1.2, {y: 0, opacity: 1, ease: easeFunc}, "0")
            break;
    }
}

function moveParallax() {
    let elements = document.querySelectorAll('[data-parallax-speed]');
    window.addEventListener('scroll', () => {
        if (elements) {
            elements.forEach((elem) => {
                let posElemScreen = elem.getBoundingClientRect().bottom;
                if (posElemScreen > 0 && posElemScreen < window.innerHeight + elem.clientHeight) {
                    let strafe = elem.dataset.parallaxSpeed;
                    let offset = (window.innerHeight - posElemScreen) * strafe / 10;
                    elem.style.transform = `translate3d(0, -${offset}px, 0)`;
                }
            })
        }
    })
}

function lazyLoad() {
    let images = document.querySelectorAll('[data-img]');
    images.forEach(image => {
        image.src = `${image.dataset.img}`;
    })
}

function lazyLoadStart() {
    let images = document.querySelectorAll('[data-img-start]');
    images.forEach(image => {
        image.src = image.getAttribute('data-img-start');
    })
}



window.onload = function () {
    if (window.innerWidth >= 1366) {
        animateOnScroll();

        let event = new Event('scroll');
        window.dispatchEvent(event);
    }
    moveParallax();
};

window.addEventListener('resize', () => {
    if (window.innerWidth >= 1366) {
        animateOnScroll();
        animateOnLoad();
    }
});

document.addEventListener('loaded', (event) => {
    animateOnLoad();
});




