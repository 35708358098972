import { tns } from 'tiny-slider/src/tiny-slider';

document.addEventListener('DOMContentLoaded', function () {
    checkSlider('.work__items', '.work__thumbs', '.work__left', '.work__right');
    checkSlider('.project__slider', '.project__thumbs', '.arrows__item_left', '.arrows__item_right');
    offsetSlider('.project__turn-list', 380, '.project__turn-left', '.project__turn-right', 0);
    similarSlider('.project__similar-list', 464, '.project__similar-left', '.project__similar-right', 43);
    advantageSlider('.advantages__items', '.advantages__left', '.advantages__right');
    worksSlider('.about-works__items', '.about-works__left', '.about-works__right');
    worksSecondSlider('.index-works__items', '.index-works__arrow_left', '.index-works__arrow_right');
});

window.addEventListener('load', function () {
    advantageProductionSlider('.production-advantages');
});

document.addEventListener('DOMContentMutated', function () {
    checkSlider('.work__items', '.work__thumbs', '.work__left', '.work__right');
});

function checkSlider(container, thumbs, left, right) {
    if (document.querySelector(container)) {
        let Slider = tns({
            container: container,
            navContainer: thumbs,
            navAsThumbnails: true,
            items: 1,
            slideBy: 'page',
            speed: 800,
        });

        activateArrows(left, right, Slider);
    }
}

function offsetSlider(container, width, left, right, gutter) {
    if (document.querySelector(container)) {
        let Slider = tns({
            container: container,
            fixedWidth: width,
            speed: 600,
            loop: false,
            gutter: gutter
        });

        activateArrows(left, right, Slider);
    }
}

function  similarSlider (container, width, left, right, gutter) {
    if (document.querySelector(container)) {
        let Slider = tns({
            container: container,
            speed: 600,
            loop: false,
            gutter: gutter,
            responsive: {
                0: {
                    fixedWidth: 300
                },
                768: {
                    fixedWidth: 300
                },
                1366: {
                    fixedWidth: width
                }
            }
        });

        activateArrows(left, right, Slider);
    }
}

function worksSlider(container, left, right) {
    if (document.querySelector(container)) {
        let Slider = tns({
            container: container,
            speed: 800,
            loop: false,
            responsive: {
                0: {
                    items: 1.2
                },
                768: {
                    items: 2.3
                },
                1366: {
                    items: 3.5
                },
                1920: {
                    items: 3.6
                }
            },
        });

        activateArrows(left, right, Slider);
    }
}

function advantageSlider(container, left, right) {
    if (document.querySelector(container)) {
        let Slider = tns({
            container: container,
            responsive: {
                0: {
                    items: 1,
                    touch: false,
                    slideBy: 'page',
                    speed: 800
                },
                768: {
                    disable: true
                }
            },
        });

        activateArrows(left, right, Slider);
    }
}

function advantageProductionSlider(container) {
    if (document.querySelector(container)) {
        let Slider = tns({
            container: container,
            autoplay: true,
            autoplayButtonOutput: false,
            autoplayTimeout: 7000,
            navPosition: 'bottom',
            nav: true,
            items: 1,
            touch: false,
            slideBy: 'page',
            speed: 800
        })
    }
}

function worksSecondSlider(container, left, right) {
    if (document.querySelector(container)) {
        let Slider = tns({
            container: container,
            items: 3,
            slideBy: 1,
            speed: 800,
            responsive: {
                0: {
                    edgePadding: -192,
                },
                768: {
                    edgePadding: -320,
                },
                1366: {
                    edgePadding: -98,
                },
                1920: {
                    edgePadding: -50,
                }
            },
        });

        activateArrows(left, right, Slider);
    }
}

function activateArrows(prevArrow, nextArrow, slider) {
    document.querySelector(prevArrow).onclick = function() {
        slider.goTo('prev');
    };
    document.querySelector(nextArrow).onclick = function() {
        slider.goTo('next');
    };
}






