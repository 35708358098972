<template>
    <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 53 (72520) - https://sketchapp.com -->
        <title>₽</title>
        <desc>Created with Sketch.</desc>
        <g id="Карточка-проекта" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" font-family="Geometria-ExtraBold, Geometria" font-size="18" font-weight="600">
            <g id="карточка-проекта" transform="translate(-81.000000, -913.000000)" fill="#333531">
                <g id="Group-11" transform="translate(-6.000000, 892.000000)">
                    <text id="₽">
                        <tspan x="87.322" y="34">₽</tspan>
                    </text>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "RubSmallIcon"
    }
</script>

<style scoped>

</style>