<template>
    <a href="javascript:void(0)" class="material-group" :class="{ active:isActive }" @click.prevent="handleClick(id)" >
        <span class="material-group__title">{{name}}</span>
        <span class="material-group__checkmark"><CheckMarkIcon/></span>
        <span class="material-group__image" :style="{backgroundImage: `url(${imageLink})`}"></span>
    </a>
</template>

<script>
    import CheckMarkIcon from '../icons/CheckmarkIcon.vue';
    export default {
        name: "WallGroupItem",
        props: {
            id: String,
            name: String,
            isActive: Boolean,
            image: String
        },
        methods: {
            handleClick(id) {
                this.$store.dispatch('options/makeWallGroupActive', id);
            }
        },
        computed: {
            imageLink() {
                return `/media/${this.image}`;
            }
        },
        components: {
            CheckMarkIcon
        }
    }
</script>

<style lang="scss">

</style>