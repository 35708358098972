<template>
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 52.3 (67297) - http://www.bohemiancoding.com/sketch -->
        <title>Group 9 Copy 6</title>
        <desc>Created with Sketch.</desc>
        <g id="Карточка-проекта" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square" stroke-opacity="0.2">
            <g id="Карточка-проекта-1920" transform="translate(-1152.000000, -3253.000000)" stroke="#000000" stroke-width="2">
                <g id="Group-4" transform="translate(222.000000, 2521.000000)">
                    <g id="Group-9-Copy-6" transform="translate(938.849242, 739.577164) rotate(-315.000000) translate(-938.849242, -739.577164) translate(928.349242, 729.077164)">
                        <path d="M10.217785,10.8933981 L0.525155558,10.8933981 L10.217785,10.8933981 L10.217785,1.26376816 L10.217785,10.8933981 Z M10.217785,10.8933981 L19.7844148,10.8933981 L10.217785,10.8933981 L10.217785,20.5230284 L10.217785,10.8933981 Z" id="Combined-Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "CrossIcon"
    }
</script>

<style scoped>

</style>