 import ParallaxSlider from "../components/parallax-slider/slider";

if (document.querySelector('.product-offer__images')) {
    let arrowLeft = document.querySelector('.product-offer__arrow_left');
    let arrowRight = document.querySelector('.product-offer__arrow_right');

    let productOfferSlider = new ParallaxSlider({
        slider: '.product-offer__images',
        speed: 10
    });

    arrowLeft.onclick = function () {
        productOfferSlider.prev();
    };

    arrowRight.onclick = function () {
        productOfferSlider.next();
    };
}

if (document.querySelector('.prod-slider__items')) {
    let arrowLeft = document.querySelector('.prod-slider__left');
    let arrowRight = document.querySelector('.prod-slider__right');

    let productOfferSlider = new ParallaxSlider({
        slider: '.prod-slider__items',
        speed: 10
    });

    arrowLeft.onclick = function () {
        productOfferSlider.prev();
    };

    arrowRight.onclick = function () {
        productOfferSlider.next();
    };
}

if (document.querySelector('.index-houses__houses-slider')) {
    let arrowLeft = document.querySelector('.index-houses__arrow-left');
    let arrowRight = document.querySelector('.index-houses__arrow-right');
    let housesSlider = new ParallaxSlider({
        slider: '.index-houses__houses-slider',
        speed: 10
    });

    arrowLeft.onclick = function() {
        housesSlider.prev();
    };

    arrowRight.onclick = function () {
        housesSlider.next();
    }
}

if (document.querySelector('.index-houses__bathrooms-slider')) {
    let arrowLeft = document.querySelector('.index-houses_bathrooms__arrow-left');
    let arrowRight = document.querySelector('.index-houses_bathrooms__arrow-right');
    let bathroomsSlider = new ParallaxSlider({
        slider: '.index-houses__bathrooms-slider',
        speed: 10
    });
    arrowLeft.onclick = function() {
        bathroomsSlider.prev();
    };

    arrowRight.onclick = function () {
        bathroomsSlider.next();
    }
}

if (document.querySelector('.index-houses__small-forms-slider')) {
    let arrowLeft = document.querySelector('.index-houses_small-forms__arrow-left');
    let arrowRight = document.querySelector('.index-houses_small-forms__arrow-right');
    let smallFormsSlider = new ParallaxSlider({
        slider: '.index-houses__small-forms-slider',
        speed: 10
    })
    arrowLeft.onclick = function() {
        smallFormsSlider.prev();
    };

    arrowRight.onclick = function () {
        smallFormsSlider.next();
    }
}

if (document.querySelector('.actions-block__items')) {
    let arrowLeft = document.querySelector('.actions-block__left');
    let arrowRight = document.querySelector('.actions-block__right');
    let actionsSlider = new ParallaxSlider({
        slider: '.actions-block__items',
        speed: 10
    });

    arrowLeft.onclick = function() {
        actionsSlider.prev();
    };

    arrowRight.onclick = function () {
        actionsSlider.next();
    }
}