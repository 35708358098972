<template>
    <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 52.3 (67297) - http://www.bohemiancoding.com/sketch -->
        <title>Group</title>
        <desc>Created with Sketch.</desc>
        <g id="Карточка-проекта" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-394.000000, -1482.000000)" id="Group-8">
                <g transform="translate(150.000000, 1277.000000)">
                    <g id="Group" transform="translate(244.000000, 205.000000)">
                        <circle id="Oval" fill="#CEAF72" cx="7.5" cy="7.5" r="7.5"></circle>
                        <polyline id="Line-3" stroke="#FFFFFF" stroke-width="2" stroke-linecap="square" points="5 8.51383304 6.56288156 10 10 6"></polyline>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "CheckmarkIcon",
    }
</script>

<style scoped>

</style>