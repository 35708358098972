<template>
    <div class="list">
        <ul class="menu-list">
            <KitItem v-for="kitItem in kitList" :key="kitItem.id"
                     :name="kitItem.name"
                     :isActive="kitItem.isActive"
                     :id="kitItem.id"
            />
        </ul>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import KitItem from './KitItem.vue'
    export default {
        name: "KitList",
        methods: {

        },
        computed: {
            ...mapGetters("options", [
                'kitList'
            ])
        },
        components: {
            KitItem
        }
    }
</script>

<style lang="scss">
    .menu-list {
        display: flex;
        margin-bottom: 33px;
    }
</style>