<template>
    <li class="calculator__project-item" :class="{active:isActive}">
        <a href="javascript:void(0)" class="calculator__project-link"
           :style="{ backgroundImage: `url('${imageLink}')`}"
           @click.prevent="handleClick(id)"
           >
            <span class="calculator__project-info">
                <span class="calculator__project-name">{{name}}</span>
            </span>
        </a>
    </li>
</template>

<script>
    import AreaIcon from '../icons/AreaIcon.vue';
    import PerimeterIcon from "../icons/PerimeterIcon.vue";
    export default {
        name: "ProjectItem",
        props: {
            id:String,
            name: String,
            area: String,
            width: String,
            length: String,
            image: String,
            isActive: Boolean,
        },
        computed: {
            imageLink() {
                return `/media/${this.image}`;
            }
        },
        methods: {
            handleClick(id) {
                this.$store.dispatch('options/makeProjectActive', id);
            }
        },
        components: {
            PerimeterIcon,
            AreaIcon
        }
    }
</script>

<style lang="scss">

</style>