import Vue from 'vue';
import Vuex from 'vuex';
import optionsModule from './modules/optionsModule';



Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        options: optionsModule,
    }
});

export default store;
