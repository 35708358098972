<template>
    <div>
        <a :href="`/finish/${activeKit.id}`" data-modal data-theme="full" class="options__question">
            <span class="options__question-icon"><QuestionIcon/></span>
            <span class="options__question-label">Что входит в {{activeKit.verboseName}}?</span>
        </a>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import QuestionIcon from '../icons/QuestionIcon.vue';
    export default {
        name: "QuestionModalItem",
        computed: {
            ...mapGetters('options', [
                'activeKit'
            ])
        },
        components: {
            QuestionIcon
        }
    }
</script>

<style>

</style>