import Vue from 'vue';
import axios from 'axios';
import getRequestPrice from '../http/http';

const optionsModule = {
    namespaced: true,
    state: {
        kitList: [
            {
                id: 1,
                name: 'Стеновой комплект',
                isActive: false,
                verboseName: 'стеновой комплект'
            },
            {
                id: 2,
                name: 'Черновая отделка',
                isActive: true,
                verboseName: 'черновую отделку'
            },
            {
                id: 3,
                name: 'Чистовая отделка',
                isActive: false,
                verboseName: 'чистовую отделку'
            }
        ],
        options: [],
        activeKit: {
            id: 2,
            name: 'Черновая отделка',
            isActive: true,
            verboseName: 'черновую отделку'
        },
        activeRoof: {},
        activeWallGroup: {},
        activeWall: {},
        activeFoundation: {},
        activeKitInfo: [],
        activeProject: [],
        wallMaterialGroups: [],
        wallMaterials: [],
        foundations: [],
        roofs: [],
        price: 0,
        kitInfo: [],
        calculatedProjects: [],
        phone: '',
        successForm: false,
        formErrors: {},
        categoryId: ''
    },
    getters: {
        kitList(store) {
            return store.kitList;
        },
        wallGroupMaterials(store) {
            return store.wallMaterialGroups;
        },
        wallMaterials(store) {
            return store.wallMaterials;
        },
        foundations(store) {
            return store.foundations;
        },
        roofs(store) {
            return store.roofs;
        },
        calculatedProjects(store) {
            return store.calculatedProjects;
        },
        activeKit(store) {
            return store.activeKit;
        },
        activeWallGroup(store) {
            return store.activeWallGroup;
        },
        activeWall(store) {
            return store.activeWall;
        },
        activeFoundation(store) {
            return store.activeFoundation;
        },
        activeRoof(store) {
            return store.activeRoof;
        },
        price(store) {
            return store.price;
            // .toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
        },
        activeKitInfo(store) {
            return store.activeKitInfo;
        },
        successForm(store) {
            return store.successForm;
        },
        formErrors(store) {
            return store.formErrors;
        },
        categoryId(store) {
            return store.categoryId;
        }
    },
    actions: {
        makeKitActive({commit, state}, id) {
            commit('setKitActive', id);
            commit('setActiveKitInfo', id);
            getRequestPrice('/projects/calculator/price', state, commit);
        },
        makeWallGroupActive({commit, state}, id) {
            commit('setWallGroupActive', id);
            commit('setMaterials');
            getRequestPrice('/projects/calculator/price', state, commit);
        },
        makeWallActive({commit, state}, id) {
            commit('setWallActive', id);
            getRequestPrice('/projects/calculator/price', state, commit);
        },
        makeFoundationActive({commit, state}, id) {
            commit('setFoundationActive', id);
            getRequestPrice('/projects/calculator/price', state, commit);
        },
        makeRoofActive({commit, state}, id) {
            commit('setRoofActive', id);
            getRequestPrice('/projects/calculator/price', state, commit);
        },
        makeProjectActive({commit, state}, id) {
            commit('setProjectActive', id);
            getRequestPrice('/projects/calculator/price', state, commit);
        },
        fetchOptions({commit, state}) {
            axios.get('/projects/options/all')
                .then((response) => {
                    let payload = response.data;
                    commit('setMaterialGroups', payload);
                    commit('setOptions', payload);
                    commit('setMaterials');
                    commit('setFoundations', payload);
                    commit('setRoofs', payload);
                    commit('setKitInfo', payload);
                    getRequestPrice('/projects/calculator/price', state, commit);
                })
                .catch(err => console.log(err));

            },
        // fetchPrice({commit}) {
        //     let projectId = window.projectId;
        //
        //     axios.get('/projects/calculator/default_price',
        //         {
        //             params: {
        //                 id: projectId
        //             }
        //         })
        //         .then((response) => {
        //             let payload = response.data;
        //             commit('setPrice', payload);
        //         })
        //         .catch(err => console.log(err));
        // },
        fetchHouses({commit}) {
            axios.get('/projects/calculator/calculated')
                .then((response) => {
                    let payload = response.data;
                    commit('setCalculatedProjects', payload);
                })
                .catch(err => console.log(err))
        },
        storePhone({commit, state}, options) {
            let form = new FormData();
            form.append('phone', state.phone);
            form.append('project_id', window.projectId);

            axios({
                method: 'post',
                url: '/projects/calculator/store',
                data: form
            })
                .then((response) => {
                    let payload = response.data;
                    if (payload.state === 'success') {
                        if (options && options.offerGoal) {
                            window.goal(options.offerGoal);
                        }
                        commit('setSuccessForm')
                    }
                    if (payload.state === 'error') {
                        let errors = payload.errors;
                        commit('setErrors', errors)
                    }
                })
                .catch(err => console.log(err))
        },
        storeCategoryId({commit}) {
            let category = window.categoryId;
            commit('setCategory', category);
        }
    },

    mutations: {
        setKitActive(store, id) {
            store.kitList.forEach((kitItem) => {
                kitItem.isActive = false;
            });
            store.kitList.filter(kitItem => kitItem.id === id).forEach((elem) => {
                Vue.set(elem, 'isActive', true);
                Vue.set(store, 'activeKit', elem);
            });
        },
        setOptions(store, payload) {
            Vue.set(store, 'options', payload)
        },
        setMaterialGroups(store, payload) {
            Vue.set(store, 'wallMaterialGroups', payload.wall_material_groups);
            store.wallMaterialGroups.map((elem) => {
                Vue.set(elem, 'isActive', false);
            });
            store.wallMaterialGroups[0].isActive = true;

            let activeWallGroup = store.wallMaterialGroups.filter(wallMaterialGroup => wallMaterialGroup.isActive === true)[0];
            Vue.set(store, 'activeWallGroup', activeWallGroup);
        },
        setWallGroupActive(store, id) {
            store.wallMaterialGroups.forEach((wallMaterialItem) => {
                wallMaterialItem.isActive = false;
            });
            store.wallMaterialGroups.filter(wallMaterialItem => wallMaterialItem.id === id).forEach((elem) => {
                elem.isActive = true;
                Vue.set(store, 'activeWallGroup', elem);
            });
        },
        setFoundationActive(store, id) {
            store.foundations.forEach((foundation) => {
                foundation.isActive = false;
            });
            store.foundations.filter(foundation => foundation.id === id).forEach((elem) => {
                elem.isActive = true;
                Vue.set(store, 'activeFoundation', elem);
            });
        },
        setWallActive(store, id) {
            store.wallMaterials.forEach((wallMaterialItem) => {
                wallMaterialItem.isActive = false;
            });
            store.wallMaterials.filter(wallMaterialItem => wallMaterialItem.id === id).forEach((elem) => {
                elem.isActive = true;
                Vue.set(store, 'activeWall', elem);
            })
        },
        setMaterials(store) {
            let activeWallGroup = store.wallMaterialGroups.filter(elem => elem.isActive === true)[0];
            store.wallMaterials = store.options.wall_materials.filter(elem => elem.wall_material_group_id === activeWallGroup.id);
            store.wallMaterials.map((elem) => {
                Vue.set(elem, 'isActive', false);
            });
            store.wallMaterials[0].isActive = true;
            let activeWall = store.wallMaterials.filter(wallMaterial => wallMaterial.isActive === true)[0];
            Vue.set(store, 'activeWall', activeWall);
        },
        setFoundations(store, payload) {
            Vue.set(store, 'foundations', payload.foundation_materials);
            store.foundations.map((elem) => {
                Vue.set(elem, 'isActive', false);
            });
            store.foundations[0].isActive = true;
            let activeFoundation = store.foundations.filter(foundation => foundation.isActive === true)[0];
            Vue.set(store, 'activeFoundation', activeFoundation);
        },
        setRoofs(store, payload) {
            Vue.set(store, 'roofs', payload.roof_materials);
            store.roofs.map((elem) => {
                Vue.set(elem, 'isActive', false);
            });
            store.roofs[0].isActive = true;
            let activeRoof = store.roofs.filter(roof => roof.isActive === true)[0];
            Vue.set(store, 'activeRoof', activeRoof);
        },
        setRoofActive(store, id) {
            store.roofs.forEach((roof) => {
                roof.isActive = false;
            });
            store.roofs.filter(roof => roof.id === id).forEach((elem) => {
                elem.isActive = true;
                Vue.set(store, 'activeRoof', elem);
            })
        },
        setKitInfo(store, payload) {
            Vue.set(store, 'kitInfo', payload.kit_info);
            let activeKitInfo = store.kitInfo.filter(elem => elem.finish_type == store.activeKit.id);
            Vue.set(store, 'activeKitInfo', activeKitInfo)
        },
        setActiveKitInfo(store, id) {
            let activeKitInfo = store.kitInfo.filter(elem => elem.finish_type == id);
            Vue.set(store, 'activeKitInfo', activeKitInfo);
        },
        setPrice(store, payload) {
            Vue.set(store, 'price', payload.price);
        },
        setCalculatedProjects(store, payload) {
            Vue.set(store, 'calculatedProjects', payload.count_projects);
            store.calculatedProjects.map((elem) => {
                Vue.set(elem, 'isActive', false);
            });
            store.calculatedProjects[0].isActive = true;
            let activeProject = store.calculatedProjects[0];
            let price = store.calculatedProjects[0].price;
            Vue.set(store, 'activeProject', activeProject);
            Vue.set(store, 'price', price);

        },
        setProjectActive(store, id) {
            store.calculatedProjects.forEach((project) => {
                project.isActive = false;
            });
            store.calculatedProjects.filter(project => project.id === id).forEach((elem) => {
                elem.isActive = true;
                Vue.set(store, 'activeProject', elem);
            });
        },
        updatePhone(store, value) {
            Vue.set(store, 'phone', value);
        },
        setSuccessForm(store) {
            Vue.set(store, 'successForm', true);
        },
        setErrors(store, errors) {
            Vue.set(store, 'formErrors', errors)
        },
        setCategory(store, payload) {
            Vue.set(store, 'categoryId', payload)
        }
    }
};

export default optionsModule;