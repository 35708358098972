<template>
    <div class="kit-info" v-if="activeKitInfo.length > 0">
        <h3 class="kit-info__title">Что входит в {{activeKit.verboseName}}?</h3>
        <KitInfoItem v-for="activeKitItem in activeKitInfo" :key="activeKitItem.id"
                     :id="activeKitItem.id"
                     :name="activeKitItem.name"
                     :check="activeKitItem.check"
        />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import KitInfoItem from "./KitInfoItem.vue";
    export default {
        name: "KitInfoList",
        computed: {
            ...mapGetters('options', [
                'activeKit',
                'activeKitInfo'
            ])
        },
        components: {KitInfoItem},
    }
</script>

<style lang="scss">

</style>