<template>
    <div>
        <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- Generator: Sketch 52.3 (67297) - http://www.bohemiancoding.com/sketch -->
            <title>Group 24</title>
            <desc>Created with Sketch.</desc>
            <g id="Карточка-проекта" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Карточка-проекта-1920" transform="translate(-242.000000, -4637.000000)">
                    <g id="Group-13-Copy-2" transform="translate(221.000000, 4355.000000)">
                        <g id="Group-26" transform="translate(21.000000, 282.000000)">
                            <g id="Group-24">
                                <rect id="Rectangle" stroke="#77B156" stroke-width="2" x="1" y="1" width="17" height="17"></rect>
                                <path d="M9.696,14.156 C8.412,14.156 7.368,13.856 6.54,13.256 L6.54,11.828 C6.876,12.128 7.332,12.38 7.896,12.596 C8.472,12.812 9.048,12.92 9.624,12.92 C10.728,12.92 11.568,12.452 11.568,11.672 C11.568,11.336 11.4,11.072 11.196,10.892 C11.1,10.808 10.956,10.724 10.764,10.64 C10.38,10.46 10.152,10.4 9.612,10.244 L9.444,10.196 C8.976,10.064 8.604,9.944 8.352,9.848 C8.1,9.752 7.824,9.608 7.512,9.428 C6.9,9.044 6.648,8.504 6.648,7.736 C6.648,6.344 7.812,5.444 9.696,5.444 C10.704,5.444 11.604,5.672 12.384,6.14 L12.384,7.46 C11.532,6.944 10.644,6.68 9.72,6.68 C8.568,6.68 8.004,7.124 8.004,7.748 C8.004,8.108 8.208,8.3 8.496,8.504 C8.652,8.6 9.036,8.72 9.24,8.804 L10.2,9.08 C10.836,9.272 11.208,9.416 11.736,9.704 C12.48,10.1 12.876,10.7 12.924,11.648 C12.924,12.428 12.612,13.04 12,13.484 C11.388,13.928 10.62,14.156 9.696,14.156 Z" id="S" fill="#77B156"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "AreaIcon"
    }
</script>

<style scoped>

</style>