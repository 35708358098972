<template>
    <div>
        <div class="project__order-desktop">
            <div class="project__order-desktop-inner" v-if="!successForm">
                <PriceItem :price="forcePrice" v-if="forcePrice > 0"/>
                <PriceItem :price="price" v-else/>
                <RecallForm :policyLink="policyLink" :offerGoal="offerGoal"/>
                <a :href="offerLink" data-modal class="project__order">
                    <span class="project__order-label">Заказать <br>индивидуальный проект</span>
                </a>
            </div>
            <SuccessItem v-else />
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import PriceItem from "./components/PriceItem.vue";
    import RecallForm from "./components/RecallForm.vue";
    import SuccessItem from "./components/SuccessItem.vue";
    export default {
        name: "Price.vue",
        props: {
            policyLink: String,
            offerLink: String,
            forcePrice: Number,
            offerGoal: String
        },
        computed: {
            ...mapGetters('options', [
                'price',
                'successForm',

            ])
        },
        mounted() {

            //this.$store.dispatch('options/fetchPrice');
        },
        components: {SuccessItem, RecallForm, PriceItem},
    }
</script>

<style>

</style>