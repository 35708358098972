<template>
    <li class="wall-item" :class="{active:isActive}">
        <a href="javascript:void(0)" class="wall-item__link"  @click.prevent="handleClick(id)">{{name.substr(3)}}мм</a>
        <span class="wall-item__icon"><CheckmarkIcon/></span>
    </li>
</template>

<script>
    import CheckmarkIcon from '../icons/CheckmarkIcon.vue';
    export default {
        name: "WallItem",
        props: {
            id: String,
            name: String,
            isActive: Boolean
        },
        methods: {
            handleClick(id) {
                this.$store.dispatch('options/makeWallActive', id);
            }
        },
        components: {
            CheckmarkIcon
        }
    }
</script>

<style lang="scss">

</style>