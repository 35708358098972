<template>
    <li class="kit-item" :class="{active:isActive}">
        <a class="kit-item__link" href="" @click.prevent="handleClick(id)">{{name}}</a>
    </li>
</template>

<script>

    export default {
        name: "KitItem",
        props: {
            id: Number,
            name: String,
            isActive: Boolean
        },
        methods: {
            handleClick(id) {
                this.$store.dispatch('options/makeKitActive', id);
            }
        }
    }
</script>

<style lang="scss">

</style>