<template>
    <div>
        <span class="project__offer-mobile-price">
            {{normalPrice}} <RubSmallIcon/>
        </span>
    </div>
</template>

<script>
    import {TweenLite} from 'gsap';
    import RubSmallIcon from "../icons/RubSmallIcon.vue";
    export default {
        name: "PriceMobileItem",
        components: {RubSmallIcon},
        props: {
            price: Number
        },
        data() {
            return {
                tweeningPrice: this.price ? this.price : 0
            }
        },
        computed: {
            normalPrice() {
                return this.tweeningPrice.toFixed(0).toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
            }
        },
        mounted() {
            this.tweeningPrice = this.price;
        },
        watch: {
            price (newValue) {
                TweenLite.to(this.$data, 0.5, {tweeningPrice: newValue});
            }
        },
    }
</script>

<style>

</style>