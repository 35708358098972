import {TweenMax, TweenLite, TimelineMax} from "gsap";

let body = document.querySelector('body');
let images = document.querySelectorAll('[data-load]');
let imagesCount = images.length;
let value = document.querySelector('.preloader__number');
let count = {imagesLoaded: 0};
body.classList.add('is-loading');

function animatePreloader() {
    if (imagesCount > 0) {
        for (let i = 0; i < imagesCount; i++) {
            let imageClone = new Image();
            imageClone.onload = imageLoaded;
            // imageClone.onerror = imageLoaded();
            imageClone.src = images[i].src;
        }
    } else {
        bindEvent();
        TweenLite.to('.preloader', 0.3, {opacity: 0});
        body.classList.remove('is-loading');
    }
}

function imageLoaded() {
    count.imagesLoaded++;
    console.log(count.imagesLoaded);
    let percent = count.imagesLoaded/imagesCount * 100;

    TweenMax.to(count, 1, {
        percent: percent,
        onUpdate: function () {
            value.innerHTML = Math.ceil(percent);
        }
    });

    if (count.imagesLoaded === imagesCount) {
        bindEvent();
        TweenLite.to('.preloader', 0.3, {opacity: 0});
        body.classList.remove('is-loading');
    }
}

function bindEvent() {
    let event = new CustomEvent('loaded', {
        detail: {}
    });
    document.dispatchEvent(event);
}

setTimeout(animatePreloader, 1000);
