<template>
    <transition name="fade">
        <div class="roof" v-if="activeKit.id === 3 && roofs">
            <h3 class="roof__title">Тип кровли</h3>
            <ul class="roof__list">
                <RoofItem v-for="roof in roofs" :key="roof.id"
                          :id="roof.id"
                          :name="roof.name"
                          :isActive="roof.isActive"
                          :image="roof.image"
                          :hidden="roof.hidden"
                />
            </ul>
        </div>
    </transition>
</template>

<script>
    import { mapGetters } from 'vuex';
    import RoofItem from './RoofItem.vue';

    export default {
        name: "RoofList",
        computed: {
            ...mapGetters('options', [
                'roofs',
                'activeKit'
                ])
        },
        components: {
            RoofItem
        }
    }
</script>

<style lang="scss">


    .fade-enter-active, .fade-leave-active {
        transition: all .8s;
    }

    .fade-enter, .fade-leave-to /* .list-leave-active до версии 2.1.8 */
    {
        opacity: 0;
        transform: translateY(30px);
    }
</style>